function MapInit(name) {
    var $map = $("#"+name);
    $map.data("map", this);
    var $selectSource = $map.parent().find(".mappicker-source");
    var options = $map.data();
    var osm = options.sources[options.source];

    $zoomInput = $("[name='"+options.zoomInput+"']");
    $latitudeInput = $("[name='"+options.latitudeInput+"']");
    $longitudeInput = $("[name='"+options.longitudeInput+"']");
    $markersInput = $("[name='"+options.markersInput+"']");

    $map.width(options.width).height(options.height);
    
    // set up the map
    var map = new L.Map(name);
    console.log(options);

    map.setView(new L.LatLng(options.latitude, options.longitude), options.zoom);
    setSource(osm);

    var osmGeocoder = new L.Control.OSMGeocoder({
        collapsed: false, /* Whether its collapsed or not */
        position: 'topright', /* The position of the control */
        text: 'Najít', /* The text of the submit button */
        placeholder: 'Najít adresu', /* The text of the search input placeholder */
        bounds: null, /* a L.LatLngBounds object to limit the results to */
        email: null, /* an email string with a contact to provide to Nominatim. Useful if you are doing lots of queries */
        callback: function (results) {
            if(results[0]){
                console.log(results);
                latlng = L.latLng(results[0].lat, results[0].lon);
                createMarker(latlng).addTo(this._map);
                this._map.flyTo(latlng, 17);
            }else{
                alert("Adresu se nepodařilo najít");
            }
        }
    });
    map.addControl(osmGeocoder);

    var markersJson = $markersInput.val();
    // console.log(markersJson);
    // var markersJson = $markersInput.val().replace(/^"(.+(?="$))"$/, '$1');
    // console.log(markersJson);
    var markers = JSON.parse(markersJson);

    $.each(markers, function(index, val){
        createMarker(L.latLng(val.latitude, val.longitude)).addTo(map);
    });

    map.on("click", function(e){
        createMarker(e.latlng).addTo(this);
    });

    map.on("zoomend", function(e){
        $zoomInput.val(this._zoom);
    });

    map.on("moveend", function(e){
        $latitudeInput.val(map.getCenter().lat);
        $longitudeInput.val(map.getCenter().lng);
    });

    map.on("layeradd", function(e){
    });

    map.on("layerremove", function(e){
        refreshInputMarkers();
    });

    $selectSource.change(function(e){
        setSource(options.sources[$(e.target).val()]);
    });

    function createMarker(latlng){
        if(!options.multipleMarkers){
            removeMarkers();
        }
        var marker = L.marker(latlng, {
            draggable: true,
            bubblingMouseEvents: false,
            type: "marker"
        }).on("click", function(){
            this._map.removeLayer(this);
        }).on("dragend", function(e){
            marker = this;
            reverseNominatim(latlng, function(data){
                // console.log(data.display_name);
                marker.options.district = getKraj(data.display_name);
                marker.options.town = data.address.hamlet;
                refreshInputMarkers();
            });
        });

        reverseNominatim(latlng, function(data){
            marker.options.district = getKraj(data.display_name);
            marker.options.town = data.address.city ? data.address.city : data.address.village ? data.address.village : data.address.hamlet;
            refreshInputMarkers();
        });

        return marker;
    }

    function getKraj(displayName){
        var kraj = "";
        $(displayName.split(",")).each(function(i,val){
            if (val.toLowerCase().indexOf("kraj") !== -1 || val.indexOf("Hlavní město Praha") !== -1){
                kraj = val;
            }
        });
        return kraj.trim();
    }

    function refreshInputMarkers(){
        var markers = [];
        map.eachLayer(function(layer){
            if(layer.options.type == "marker"){
                markers.push({ latitude: layer._latlng.lat, longitude: layer._latlng.lng, district:layer.options.district, town: layer.options.town })
            }
        });
        console.log(markers);
        $markersInput.val(JSON.stringify(markers));
    }

    function removeMarkers() {
        map.eachLayer(function(layer){
            if(layer.options.type == "marker"){
                map.removeLayer(layer);
            }
        });
        $markersInput.val(JSON.stringify([]));
    }

    function setSource(source){
        map.removeLayer(osm);
        osm = new L.TileLayer(source.url, source.meta);
        map.addLayer(osm);
    }

    function destroy() {
        map.remove();
    }
}
if(typeof google !== 'undefined') {
    var geocoder = new google.maps.Geocoder;
}
function geocodeLatLng(latLng, marker) {
    console.log({
        "geocode": latLng
    });
    geocoder.geocode({'location': latLng, "language": "cs", "components":""}, function(results, status) {
        console.log({google: results, marker: marker});
    });
}

function reverseNominatim(latLng, callback) {
    $.ajax({
        url: "https://nominatim.openstreetmap.org/reverse",
        method: "GET",
        data: { format: "json", addressdetails: 1, lat: latLng.lat, lon: latLng.lng, admin_level:6},
        success: function(data){
            callback(data);
        }
    });
}

function nominatim(query, callback) {

}